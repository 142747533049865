import {SvgIcon} from "@mui/material";

export const GoBackIcon = (props) => {
    return (
            <SvgIcon
                viewBox="-8.32 -8.32 80.64 80.64" xmlns="https://www.w3.org/2000/svg" strokeWidth="1.92"
                stroke="#006e8a" fill="none" transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)" {...props}>
                <g strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-8.32" y="-8.32" width="80.64" height="80.64" rx="40.32" strokeWidth="0"></rect>
                </g>
                <g strokeLinecap="round" strokeLinejoin="round" stroke="#000000" strokeWidth="0.256"></g>
                <g>
                    <polyline points="46.02 21.95 55.93 31.86 46.02 41.77"></polyline>
                    <line x1="55.93" y1="31.86" x2="19.59" y2="31.86"></line>
                    <path
                        d="M40,38.18V52a2.8,2.8,0,0,1-2.81,2.8H12A2.8,2.8,0,0,1,9.16,52V11.77A2.8,2.8,0,0,1,12,9H37.19A2.8,2.8,0,0,1,40,11.77V25"></path>
                </g>
            </SvgIcon>
    )
}