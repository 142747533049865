import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./themes/theme";
import EmptyLayout from "./components/EmptyLayout";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Routes>
              <Route element={<Layout />} >
                  {AppRoutes.filter(x => x.hasLayout).map((route, index) => {
                      const { element, ...rest } = route;
                      return <Route key={index} {...rest} element={element} />;
                  })}
              </Route>
              <Route element={<EmptyLayout />} >
                  {AppRoutes.filter(x => !x.hasLayout).map((route, index) => {
                      const { element, ...rest } = route;
                      return <Route key={index + 10} {...rest} element={element} />;
                  })}
              </Route>
          </Routes>
      </ThemeProvider>
    );
  }
}
