import React, {useCallback, useEffect} from 'react';
import {
  Box,
  Container, Fab,
  IconButton,
  Link,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
  useScrollTrigger,
  Zoom
} from '@mui/material';
import ChannelListItem from './ChannelListItem';
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import SearchIcon from '@mui/icons-material/Search';
import YoutuberPicker from "./YoutuberPicker";
import {KeyboardArrowUp} from "@mui/icons-material";

export default function Home() {
  const [channels, setChannels] = React.useState([]);
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('channel/top-video');
      const data = await response.json();
      setChannels(data);
      
    };
    
    fetchData()
    .catch(console.error);

  }, []);

  useEffect(() => {
    // scroll to section if redirect from video page
    if (location.state && location.state.section) {
      const section = document.getElementById(location.state.section);
      if (section) {
        section.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [channels])
  
  const toggleDrawer = (newOpen) => {
    setOpenDrawer(newOpen);
  }

  const trigger = useScrollTrigger({
    // Number of pixels needed to scroll to toggle `trigger` to `true`.
    threshold: 100,
  })

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta name="keywords"
              content="小撮美食地圖、美食地圖、景點地圖、台灣美食、旅遊目的地、餐廳推薦、旅遊指南、當地風味、冒險探索、地圖導航、台灣之旅、食在台灣、遊台灣"/>
        <meta name="description"
              content="跟隨知名的YouTube創作者(youtuber)，發現台灣各地的美食和令人驚嘆的景點。小撮美食地圖為您提供詳細的地圖和指南，讓您輕鬆計畫自己的美食之旅和冒險之旅。"/>
      </Helmet>
      <Container maxWidth="lg" sx={{mt: 15}}>
        <Link color="text.secondary"
              underline='none'
              onClick={() => toggleDrawer(true)}
              >
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#fff', 
            border:'1px solid', borderColor:'grey.500', borderRadius:'5px', mb:2}}>
            <Box>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Box>
            <Box sx={{flexGrow:1}}>
              <Typography sx={{color:'grey'}}>所有Youtuber</Typography>
            </Box>
          </Box>
        </Link>
        <Stack 
          direction={"column"}
          spacing={2}
        >
          {
            channels && channels.map(c => {
              return(
                  <Paper key={c.id} width='100%' sx={{p:2}}>
                    <ChannelListItem channel={c} />
                  </Paper>
              )
            })
          }
        </Stack>
      </Container>
      <SwipeableDrawer anchor='right' open={openDrawer} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)}>
        <YoutuberPicker channels={channels} handleChannelClick={() => toggleDrawer(false)} />
      </SwipeableDrawer>
      <Zoom in={trigger}>
        <Box
            role="presentation"
            // Place the button in the bottom right corner.
            sx={{
              position: "fixed",
              bottom: 32,
              right: 32,
              zIndex: 1,
            }}
        >
          <Fab
              onClick={scrollToTop}
              color="primary"
              size="small"
              aria-label="Scroll back to top"
          >
            <KeyboardArrowUp fontSize="medium" />
          </Fab>
        </Box>
      </Zoom>
    </React.Fragment>
  );
}

