import React, {useState, useEffect} from 'react';
import {Box, Button, FormLabel, OutlinedInput, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function VideoAddedPage() {
    const initialRequestState = {
        VideoId: '',
        PlaceId: '',
        StartAt: 0,
    }
    
    const [placeRequest, setPlaceRequest] = useState(initialRequestState);
    const [placeResponse, setPlaceResponse] = useState(null);
    
    const handleChange = (event) => {
        const { name, value } = event.target

        setPlaceRequest({ ...placeRequest, [name]: value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(placeRequest);
        fetch('channel/place/add', {
          method: 'POST',
          headers: {
            // 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(placeRequest),
          credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parsing the response to JSON
        }).then(data => {
            setPlaceResponse(data);
            console.log(data); // Handle the JSON data here
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    }
    
    return(
        <React.Fragment>
        <Grid container direction="column" spacing={1}>
            <Grid item  xs={12} md={12}>
                <FormLabel htmlFor='video-VideoId' required>VideoId</FormLabel>
                <OutlinedInput
                    id='video-VideoId'
                    type='number'
                    value={placeRequest.VideoId || ''}
                    name='VideoId'
                    size='small'
                    required
                    onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={12}>
                <FormLabel htmlFor='video-PlaceId' required>PlaceId</FormLabel>
                <OutlinedInput
                    id='video-PlaceId'
                    value={placeRequest.PlaceId || ''}
                    name='PlaceId'
                    size='small'
                    required
                    onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={12}>
                <FormLabel htmlFor='video-StartAt' required>StartAt</FormLabel>
                <OutlinedInput
                    id='video-StartAt'
                    type='number'
                    value={placeRequest.StartAt || ''}
                    name='StartAt'
                    size='small'
                    required
                    onChange={handleChange} />
            </Grid>
            <Button variant='outlined' onClick={handleSubmit} >submit</Button>
        </Grid>
        {placeResponse && (
                <Box sx={{mt:6}}>
                    <Typography variant='body1'>PlaceId: {placeResponse.placeId}</Typography>
                    <Typography variant='body1'>Name: {placeResponse.name}</Typography>
                    <Typography variant='body1'>Link: {placeResponse.link}</Typography>
                </Box>
            )
        }
        </React.Fragment>


    )
}