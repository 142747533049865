import React from "react";
import {Box, ButtonBase, Stack, Typography} from "@mui/material";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import YouTubeIcon from "@mui/icons-material/YouTube";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";

export default function AboutUs() {
    const navigate = useNavigate();
    
    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: 60,
        [theme.breakpoints.down('sm')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &.Mui-focusVisible': {
            zIndex: 1,
            '& .MuiImageBackdrop-root': {
                opacity: 0.15,
            },
            '& .MuiImageMarked-root': {
                opacity: 0,
            },
            '& .MuiTypography-root': {
                border: '4px solid currentColor',
            },
        },
    }));
    const Image = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#be7c01',
    }));
    const ImageMarked = styled('span')(({ theme }) => ({
        height: 3,
        width: 18,
        backgroundColor: '#be7c01',
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    }));
    return (
        <Box sx={{ display: "flex", flexDirection:'column', pt:10, height:"100vh", width:'100vw', color:'grey.700' }}>
            <Box sx={{ position:'relative', width:'100%', height: {md:'600px', xs:'500px'} }}>
                <Box sx={{width: 'inherit', height: 'inherit'}}>
                    <Box component="img"
                         sx={{objectFit: 'cover',width: 'inherit', height: 'inherit'}}
                         alt="小撮美食地圖"
                         src='images/pexels-igor-ovsyannykov-56123-205961.jpg'
                    ></Box>
                </Box>
                
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'start', 
                    position:"absolute", width: { md:'40%', xs:'80%'}, top: {md: '20%', xs: '10%'}, left:'10%', gap:2, 
                    backgroundColor:"rgba(255, 255, 255, 0.7)", p:3, borderRadius:1}}>
                    <Typography variant="h4" component="h1" >小撮美食地圖</Typography>
                    <Typography variant="body1" >
                        歡迎來到我們的網站，這裡是一個專門收集來自各大 YouTuber 關於台灣美食、餐廳、景點及購物介紹影片的平台。我們的目標是透過創意的方式，將這些影片和地圖結合，讓您能夠輕鬆找到各位 YouTuber 推薦的熱點，並且根據他們的介紹進行探索。
                    </Typography>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', width:'100%', }}>
                        <ImageButton
                            focusRipple
                            style={{
                                width: '180px',
                            }}
                            onClick={() => {navigate("/")}}
                        >
                            <Image>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    sx={(theme) => ({
                                        position: 'relative',
                                        p: 2,
                                        pt: 2,
                                        pb: `calc(${theme.spacing(1)} + 6px)`,
                                        fontWeight:'bold',
                                        letterSpacing:'0.5rem',
                                        fontSize:'1.15rem'
                                    })}
                                >
                                    開始探索
                                    <ImageMarked className="MuiImageMarked-root" />
                                </Typography>
                            </Image>

                        </ImageButton>
                    </Box>
                </Box>
            </Box>
            <Box sx={{position:'relative', width:'100%', height:{md:'500px',xs:'400px'} }}>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'start', position:"absolute", width:'60%', top: { md:'30%', xs:'20%'},right:'10%', maxWidth:'500px'}}>
                    <Typography variant="body1">
                        台灣是美食天堂、自然景觀豐富的寶島，不論是充滿獨特風味的小吃攤、歷史悠久的餐廳，還是秘境般的自然景點，都有著吸引人的魅力。然而，隨著數位內容的多元發展，許多知名的 YouTuber 經常以影片的形式分享他們的旅遊和美食經驗，帶我們深入探索台灣的各個角落。我們的網站正是基於這樣的理念，將這些寶貴的影片集中在一起，並搭配互動式地圖，讓使用者可以根據地理位置找到他們感興趣的美食、景點或購物地點。
                    </Typography>
                </Box>
                <Box sx={{height:'inherit',}}>
                    <Box component="img"
                         sx={{objectFit: 'cover', width: '100%', height: '100%' ,transform: 'transform 0.3s ease-in-out'}}
                         alt="小撮美食地圖"
                         src='images/turquoise-blue-brush-stroke-design-white-background_23-2148153204.jpg'
                    ></Box>
                </Box>

            </Box>
            <Box sx={{position:'relative', width:'100%', height: {md:'500px',xs:'400px'} }}>
                <Box sx={{backgroundColor:'#faf7de', height:'100vh'}}>
                    
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', position:"absolute", width:'70%', top:{md:'20%',xs:'10%'},left:'15%'}}>
                    <Box sx={{display:'flex',width:'100%',justifyContent:'center', pb:2}}>
                        <MapOutlinedIcon sx={{fontSize:'5rem',color: 'green'}}/>
                    </Box>
                    <Typography variant="body1">
                        透過我們的地圖功能，您不僅可以根據地點來篩選影片內容，還能根據您的興趣找到最適合的推薦。例如，如果您對台北的夜市小吃情有獨鍾，您只需點擊地圖上的台北地區，我們將自動為您篩選出所有相關的 YouTuber 影片推薦。若您正在計劃一趟環島旅行，我們的網站也能夠幫助您找到不同地區的推薦景點，從台北的101大樓到墾丁的白沙灣海灘，讓您的旅行計劃更加豐富多彩。
                    </Typography>
                </Box>
                
            </Box>
            <Box sx={{position:'relative',  width:'100%', height:{md:'500px',xs:'400px'} }}>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', position:"absolute", width:'50%', top:{md:'25%', xs:'15%'}, left:'15%', maxWidth:'500px'}}>
                    <Box sx={{display:'flex',width:'100%',justifyContent:'center', pb:2}}>
                        <YouTubeIcon sx={{fontSize:'5rem',color: 'red'}}/>
                    </Box>
                    <Typography variant="body1">
                        我們的網站還能讓您根據特定的 YouTuber 進行搜尋，如果您特別喜歡某位 YouTuber 的風格，您可以直接觀看他或她所推薦的所有地點。我們相信，透過這樣的整合，無論您是想要尋找下一個必吃餐廳、或是計劃一場充滿驚喜的週末旅程，我們都能為您提供靈感和參考。
                    </Typography>
                </Box>
                <Box sx={{height:'inherit',}}>
                    <Box component="img"
                         sx={{objectFit: 'cover', width: '100%', height: '100%' ,transform: 'transform 0.3s ease-in-out'}}
                         alt="小撮美食地圖"
                         src='images/watercolor-hand-drawn-background_23-2147836214.jpg'
                    ></Box>
                </Box>
            </Box>
            <Box sx={{position:'relative', width:'100%', height:'300px' }}>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', position:"absolute", width:'70%', top:'45%',left:'15%', textAlign:'center'}}>
                    <Typography variant="body1">
                        探索台灣從未如此簡單且有趣，我們誠摯邀請您加入我們的旅程，透過這些 YouTuber 的鏡頭，發現更多台灣的美好！
                    </Typography>
                </Box>
                <Box component="img"
                     sx={{objectFit: 'cover', width: '100%', height: '100%' ,transform: 'transform 0.3s ease-in-out'}}
                     alt="小撮美食地圖"
                     src='images/strokes-gray-paint_23-2147763480.avif'
                ></Box>
            </Box>
        </Box>
    )
}

