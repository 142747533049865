import Home from "./components/Home";
import Video from "./components/Video";
import PlaceAdded from "./components/PlaceAdded";
import Channel from "./components/Channel";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutUs from "./components/AboutUs";
import SiteMap from "./components/SiteMap";

const AppRoutes = [
  {
    index: true,
    hasLayout:true,
    element: <Home />
  },
  {
    path: '/youtuber/:id',
    hasLayout:true,
    element: <Channel />
  },
  {
    path: '/video',
    hasLayout:false,
    element: <Video />
  },
  {
    path: '/Admin/Place',
    hasLayout:false,
    element: <PlaceAdded />
  },
  {
    path: '/Admin/sitemap',
    hasLayout:false,
    element: <SiteMap />
  },
  {
    path: '/PrivacyPolicy',
    hasLayout:true,
    element: <PrivacyPolicy />
  },
  {
    path: '/AboutUs',
    hasLayout:true,
    element: <AboutUs />
  },
];

export default AppRoutes;
