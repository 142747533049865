import {SvgIcon} from "@mui/material";

export const Logo = (props) => {
    return (
        <SvgIcon
            viewBox="0 0 110 110" xmlns="https://www.w3.org/2000/svg" {...props}>
            <path fill="#EB2C3F" opacity="1.000000" stroke="none"
                  d="
M81.393707,78.381699 
	C73.021469,87.618713 64.900742,96.601967 56.323750,106.089928 
	C43.056381,90.956223 27.633842,78.564743 19.414598,60.463985 
	C13.100083,46.557915 15.139688,32.917850 24.220022,20.912107 
	C33.692062,8.388459 46.811581,3.124723 62.435390,5.463042 
	C90.390587,9.646920 105.175224,41.473537 90.431084,65.499878 
	C87.753433,69.863251 84.586357,73.926262 81.393707,78.381699 
M31.508135,53.501205 
	C33.261066,60.074337 36.789593,65.946457 42.880390,68.557617 
	C51.259617,72.149841 60.288357,74.227013 69.128563,76.981247 
	C69.123680,69.853119 69.123688,69.853127 74.496414,64.339325 
	C83.428871,55.172318 83.949806,41.207668 75.752678,30.662800 
	C68.397247,21.200693 53.598625,18.469696 43.248421,24.783964 
	C32.691250,31.224497 29.705292,40.997086 31.508135,53.501205 
z"/>
            <path fill="#F69927" opacity="1.000000" stroke="none"
                  d="
M29.049160,83.939957 
	C37.216221,92.819542 45.182655,101.407654 53.827351,110.726967 
	C40.302383,110.726967 27.888744,111.147339 15.523148,110.558586 
	C8.832876,110.240059 2.555620,103.876282 2.328176,97.508408 
	C1.866643,84.586571 2.190303,71.636696 2.190303,58.843250 
	C2.803918,58.548382 3.077790,58.337978 3.379878,58.283215 
	C9.771791,57.124462 13.753250,58.787498 16.622927,65.577599 
	C19.400457,72.149658 24.672276,77.667564 29.049160,83.939957 
z"/>
            <path fill="#10A39F" opacity="1.000000" stroke="none"
                  d="
M109.995407,94.661240 
	C108.630806,105.502121 102.715950,110.926987 92.501457,110.986061 
	C81.525040,111.049538 70.547974,111.000397 58.205990,111.000397 
	C62.396576,106.350075 65.921822,102.404884 69.484482,98.493774 
	C73.173950,94.443420 77.110336,90.596390 80.547699,86.344322 
	C83.703270,82.440842 87.172081,80.531822 92.405098,81.034981 
	C97.986137,81.571609 103.658752,81.155807 109.999825,81.155807 
	C109.999825,85.703506 109.999825,89.954620 109.995407,94.661240 
z"/>
            <path fill="#EFE2AF" opacity="1.000000" stroke="none"
                  d="
M100.393341,53.213882 
	C103.237190,30.960920 94.614830,14.766024 74.780220,3.067651 
	C82.360176,3.067651 88.775360,2.657248 95.116371,3.167885 
	C103.723030,3.860972 109.950150,11.075854 109.973625,19.703272 
	C110.023888,38.179142 109.989021,56.655243 109.989021,75.559425 
	C103.389107,75.559425 97.157822,75.559425 91.621727,75.559425 
	C94.401993,68.490730 97.324760,61.059753 100.393341,53.213882 
z"/>
            <path fill="#B8D843" opacity="1.000000" stroke="none"
                  d="
M8.572591,52.856293 
	C6.337798,52.857635 4.532489,52.857635 2.002157,52.857635 
	C2.002157,47.595211 2.000052,42.675430 2.002683,37.755650 
	C2.006063,31.435644 1.835454,25.109276 2.061260,18.797152 
	C2.384473,9.762102 9.050200,3.255449 18.088764,3.040569 
	C24.371363,2.891208 30.660376,3.011819 36.025650,3.011819 
	C30.966143,7.765885 24.982431,12.386568 20.259108,18.057102 
	C12.839935,26.964106 10.159738,37.694260 11.144062,49.278797 
	C11.289080,50.985504 12.246657,53.178616 8.572591,52.856293 
z"/>
            <path fill="#FFFBFB" opacity="1.000000" stroke="none"
                  d="
M31.396461,53.093266 
	C29.705292,40.997086 32.691250,31.224497 43.248421,24.783964 
	C53.598625,18.469696 68.397247,21.200693 75.752678,30.662800 
	C83.949806,41.207668 83.428871,55.172318 74.496414,64.339325 
	C69.123688,69.853127 69.123680,69.853119 69.128563,76.981247 
	C60.288357,74.227013 51.259617,72.149841 42.880390,68.557617 
	C36.789593,65.946457 33.261066,60.074337 31.396461,53.093266 
z"/>
        </SvgIcon>
    )
}