import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: '#F5F5F5', // Mainly used for navigation bar background color
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:`
                body: {
                    background-color: white,
                },
              `,
            
        },
    }
});

export default theme;
