import {
    Box,
    Button, ButtonGroup,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Chip,
    Typography
} from '@mui/material';
import React, {useRef} from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import NearMeIcon from '@mui/icons-material/NearMe';
import StarRateIcon from '@mui/icons-material/StarRate';
import { grey } from '@mui/material/colors';

export default function VideoPlace({youtubeLink, place}) {
    const placeRef = useRef(place);
    
    const lackImageUri = "https://i.imgur.com/QA29bm6.png";
    
    const watchClip = () => {
        window.open(`${youtubeLink}&t=${placeRef.current.startAt}s`, "_blank")?.focus();
    };
    const gmapNavi = () => {
        window.open(`${placeRef.current.placeDetail.googleLink}`, "_blank")?.focus();
    };
    
    return(
        <Card sx={{minHeight:200, minWidth:350, maxWidth: 350, display: 'flex' }}>
            <CardMedia 
                component='img' 
                image={place.placeDetail.photo === null ? lackImageUri : place.placeDetail.photo}
                alt=''
                sx={{
                    p:0.5,
                    borderRadius:3,
                    width: 120,
                }} />
            <Box sx={{ position:"relative",width:"100%", display: 'flex', flexDirection: 'column'}}>
                <CardActionArea>
                    <CardContent>
                        <Typography 
                            component='h3'
                            sx={{
                                color: grey[700],
                                fontSize: '1.125rem',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                fontWeight: 'bold',
                            }}
                        >{place.placeDetail.name}</Typography>
                        <Typography 
                            variant='body1'
                            sx={{
                                color: grey[600],
                                fontSize: '1rem',
                                top: '165px',
                                left: '10px',
                            }}>
                            {place.placeDetail.address}
                        </Typography>
                        <Box component="div" sx={{ display: 'flex', flexDirection: 'row'}}>
                            <Chip
                                icon={<StarRateIcon/>}
                                label={place.placeDetail.rating.toFixed(1)}
                                color="warning"
                                size='small'
                            />    
                            <Typography sx={{ml:'5px', pt:'1px', color:grey[600]}}>{`(${place.placeDetail.userRatingsTotal}則評論)`}</Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
                <Box sx={{ position:"absolute", bottom:0,  display: 'flex', justifyContent:'end', alignItems: 'center', pr: 2, pb: 1, width: 'inherit' }}>
                    <ButtonGroup variant="text" size="small">
                        <Button onClick={watchClip}>
                            <YouTubeIcon sx={{fontSize:'1.25rem', color: 'red', mr:'5px' }} />
                            <Typography sx={{fontSize:'0.8rem'}}>影片</Typography>
                        </Button>
                        <Button onClick={gmapNavi}>
                            <NearMeIcon sx={{fontSize:'1.25rem',color:'green', mr:'5px'}} />
                            <Typography sx={{fontSize:'0.8rem'}}>前往</Typography>
                        </Button>
                    </ButtonGroup>
                </Box>
            </Box>
        </Card>
    )
}