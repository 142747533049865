import React from "react";
import {Box, Container, List, ListItem, Typography} from "@mui/material";


export default function PrivacyPolicy() {
    const sectionHeaderStyle = {
        pt:6,
        pb:1,
    }
    
    return (
        <Container maxWidth="lg" sx={{mt:10}}>
            <Box sx={{ display: 'flex', flexDirection:'column', justifyContent: 'start', alignItems: 'left' }}>
                <Typography variant="h3" component="h1" sx={{display:'flex', pb:2, width:'100%', justifyContent:'center'}}>Privacy & Policy</Typography>
                <Typography variant="body1">本部落格（以下簡稱「我們」）重視您的隱私權。本隱私權政策將解釋我們如何收集、使用、分享和保護您的個人資訊。請詳細閱讀以下內容，以瞭解我們的隱私權政策。</Typography>
                
                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>1. 資訊收集</Typography>
                <Typography variant="body1">
                    我們可能在以下情況下收集您的個人資訊：
                    <List>
                        <ListItem>當您訂閱我們的電子報或通知時，我們可能會收集您的電子郵件地址。</ListItem>
                        <ListItem>當您在我們的部落格上發表評論或回覆時，我們可能會收集您的名稱、電子郵件地址和網站（選填）。</ListItem>
                        <ListItem>我們可能使用Cookie和類似的技術來自動收集您的IP地址、瀏覽器類型、訪問日期和時間以及您在我們部落格上的活動。</ListItem>
                    </List>
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>2. 資訊用途</Typography>
                <Typography variant="body1">
                    我們可能使用您的個人資訊來：
                    <List>
                        <ListItem>向您發送我們的最新文章、電子報或其他通知。</ListItem>
                        <ListItem>管理並回應您在部落格上的評論或回覆。</ListItem>
                        <ListItem>改進我們的部落格內容和功能。</ListItem>
                        <ListItem>進行統計分析和研究，以了解我們的讀者偏好和行為。</ListItem>
                    </List>
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>3. 資訊分享</Typography>
                <Typography variant="body1">
                    我們承諾不會未經您的明確同意而出售、租賃或分享您的個人資訊給第三方。但在以下情況下，我們可能會分享您的資訊：
                    <List>
                        <ListItem>當我們需要遵守適用法律法規、法庭命令或政府機構的要求時。</ListItem>
                        <ListItem>當我們相信必須保護我們的部落格和讀者的安全。</ListItem>
                    </List>
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>4. Cookie 和跟蹤技術</Typography>
                <Typography variant="body1">
                    我們使用Cookie和類似的技術來優化您的瀏覽體驗，並收集統計信息。您可以隨時在瀏覽器設置中管理Cookie的使用，包括拒絕Cookie。
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>5. 廣告和分析</Typography>
                <Typography variant="body1">
                    我們可能使用第三方廣告合作夥伴和分析服務提供商，以了解我們的讀者行為和改進廣告效果。這些合作夥伴可能會使用Cookie和類似的技術來提供廣告。您可以在設置中管理對這些Cookie的同意。
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>6. 用戶權利</Typography>
                <Typography variant="body1">
                    您擁有以下權利：
                    <List>
                        <ListItem>查看、更正或刪除您的個人資訊。</ListItem>
                        <ListItem>限制我們處理您的個人資訊。</ListItem>
                        <ListItem>撤回您對使用Cookie的同意。</ListItem>
                        <ListItem>如果您想行使這些權利或有其他隱私權方面的問題，請聯絡我們（聯絡方式見下文）。</ListItem>
                    </List>
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>7. 隱私權政策的更新</Typography>
                <Typography variant="body1">
                    我們可能會定期更新本隱私權政策，以反映法律變化和部落格實踐的變化。請定期查閱本政策以確保您了解最新的內容。
                </Typography>

                <Typography variant="h4" component="h2" sx={{...sectionHeaderStyle}}>8. 聯絡方式</Typography>
                <Typography variant="body1">
                    如果您對我們的隱私權政策有任何問題或疑慮，或希望行使您的隱私權利，請聯絡我們：
                    <List>
                        <ListItem>聯絡信箱：sotshau.fun@gmail.com</ListItem>
                        <ListItem>本政策最後更新於 2024-08-10</ListItem>
                    </List>
                </Typography>
            </Box>
        </Container>

    )
}