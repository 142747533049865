import {Avatar, Box, IconButton, Stack, Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import {useSearchParams,useNavigate} from 'react-router-dom';
import VideoPlace from './VideoPlace';
import VideoMap from "./VideoMap";
import {grey, teal} from "@mui/material/colors";
import {GoBackIcon} from "../svg-icons/GoBackIcon";
import dayjs from "dayjs";
import {Helmet} from "react-helmet";

export default function Video() {
    const initialVideoState = {
        id:0,
        channel: {},
        places:[],
    }

    const navigate = useNavigate();
    const [videoInfo, setVideoInfo] = useState(initialVideoState);
    
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const source = searchParams.get('source');
    
    const minPlaceHeight = 100;
    const fullPlaceHeight = 340;
    
    const [placeHeight, setPlaceHeight] = useState(fullPlaceHeight);
    const [showPlaces, setShowPlaces] = useState(true);
    
    const [placeNames, setPlaceNames] = useState(''); 
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`channel/video/${id}`);
            const data = await response.json();
            setVideoInfo(data);
            setPlaceNames(data.places.map(p => p.placeDetail.name));
          }
          
          fetchData()
          .catch(console.error);
        
    }, [id]);

    const togglePlacesPanel = () => {
        setPlaceHeight((prevHeight) => prevHeight === fullPlaceHeight ? minPlaceHeight : fullPlaceHeight);
        setShowPlaces((prevShow) => !prevShow);
    }

    const handleGobackClick = () => {
        if (source === "home") {
            navigate(`/`,{ state: { section: `${videoInfo.channel.channelId}` } });
        }
        
        if (source === "channel" || source === "" || source === null) {
            navigate(`/youtuber/${videoInfo.channel.channelId}`);
        }
    }
    
    const Puller = styled('div')(({ theme }) => ({
        width: 40,
        height: 6,
        backgroundColor: teal[500],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)',
        ...theme.applyStyles('dark', {
            backgroundColor: grey[900],
        }),
    }));
    
    return (
        <Box sx={{ bgcolor: '#cfe8fc', height: '100vh', width: '100vw', }}>
            <Helmet>
                <title>{`${videoInfo.channel.name}帶你去 | ${videoInfo.name}｜${videoInfo.channel.name} - 小撮美食地圖`}</title>
                <meta name="keywords"
                      content={`小撮美食地圖,${videoInfo.channel.name},${placeNames}`}/>
                <meta name="description"
                      content={`來小撮美食地圖跟著知名的YouTube創作者【${videoInfo.channel.name}】一起探索${videoInfo.region}的美食和令人驚嘆的景點。跟著創作者一起走訪${placeNames}`}/>
            </Helmet>
            <Stack>
                <Box sx={{position: "relative", height: `calc(100vh - ${placeHeight}px)`, width: '100vw'}}>
                    <VideoMap places={videoInfo.places}/>
                </Box>
                <Box sx={{position: "relative", display:showPlaces ? "flex" : "none", flexDirection: 'column', bgcolor: 'background.default', height: `${fullPlaceHeight}px`, width: '100vw' }}>
                    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", pt:1.5}}>
                        <Puller onClick={togglePlacesPanel} />
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"start", alignItems:"center", p:1.5, flexGrow: 1}}>
                        <Avatar alt={videoInfo.channel.name} src={videoInfo.channel.logo} sx={{ width: 40, height: 40 }}/>
                        <Box sx={{display:"flex",flexDirection: 'column', ml:'10px'}}>
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '1.12 rem',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    fontWeight: 'normal',
                                }}
                            >{videoInfo.name}</Typography>
                            <Box sx={{display:"flex", color: 'text.secondary',}}>
                                <Typography sx={{flexGrow: 1 }}>{videoInfo.channel.name}</Typography>
                                <Typography>{`發佈日期：${dayjs(videoInfo.releaseDate).format('YYYY-MM-DD')}`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap:1, maxWidth:'100%', overflow: 'auto', p:1 }}>
                        {videoInfo.places && videoInfo.places.map((place) => {
                            return(
                                <VideoPlace key={place.id} youtubeLink={videoInfo.link} place={place}/>
                            )
                        })}
                    </Box>
                </Box>
                <Box sx={{ position:"relative", display:!showPlaces ? "flex" : "none", flexDirection: 'column', bgcolor: 'background.default', height: `${minPlaceHeight}px`, width: '100vw' }}>
                    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", pt:1.5}}>
                        <Puller onClick={togglePlacesPanel} />
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"start", alignItems:"center", p:1.5}}>
                        <Avatar alt={videoInfo.channel.name} src={videoInfo.channel.logo} sx={{ width: 40, height: 40 }}/>
                        <Box sx={{display:"flex",flexDirection: 'column', ml:'10px'}}>
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '1.12 rem',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    fontWeight: 'normal',
                                }}
                            >{videoInfo.name}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Stack>
            <IconButton
                onClick={handleGobackClick}    
                sx={{position:"absolute", width:40, height:40, left:0, top:0, mx:1, my:1, p:"0.25rem", zIndex:9999, bgcolor:'rgba(255, 255, 255, 0.75)'}}>
                <GoBackIcon sx={{fontSize:'2rem', bgcolor: "white", border:1, borderRadius:'50%', borderColor:'#006e8a', fill:'none'}} />
            </IconButton>
        </Box>
    )
}