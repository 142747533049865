import {SvgIcon} from "@mui/material";

export default function MyPositionIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 100 100" fill="#000000" 
            xmlns="https://www.w3.org/2000/svg"
            {...props}    
        >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
                <path strokeWidth="1"
                    d="M43 0v13.166C27.944 16.03 16.03 27.944 13.166 43H0v14h13.166C16.03 72.056 27.944 83.97 43 86.834V100h14V86.834C72.056 83.97 83.97 72.056 86.834 57H100V43H86.834C83.97 27.944 72.056 16.03 57 13.166V0H43zm7 22.5A27.425 27.425 0 0 1 77.5 50A27.425 27.425 0 0 1 50 77.5A27.425 27.425 0 0 1 22.5 50A27.425 27.425 0 0 1 50 22.5z"
                    fill="#006e8a"></path>
                <circle r="15" cy="50" cx="50" fill="#006e8a"></circle>
            </g>
        </SvgIcon>
    )
}