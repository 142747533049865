import React from 'react';
import AppBar from '@mui/material/AppBar';
import './NavMenu.css';
import { Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NaviBlur from "./NaviBlur.component";
import {Logo} from "../svg-icons/logo";
import {useNavigate} from "react-router-dom";

export default function NavMenu() {
    const pages = [
        {
            name: '關於我',
            path: 'AboutUs',
        },
        {
            name: '隱私權政策',
            path: 'PrivacyPolicy',
        },
    ];
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const navigate = useNavigate();
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
    
    const handleAction = (e, path) => {
        navigate(path);
    }

    return (
      <header>
        <AppBar elevation={0} position='fixed' sx={{bgcolor: 'rgba(0, 0, 0, 0)'}}>
          <Container maxWidth="x1" sx={{position: 'relative', color:"grey.700"}}>
            <Toolbar 
                sx={{bgcolor: "inherit", zIndex: 10}}
                disableGutters>
                <Box
                    component="a"
                    href="#app-bar-with-responsive-menu"
                    sx={{
                        mx:0,
                        display: { xs: 'none', md: 'flex' },
                        color: 'inherit',
                        textDecoration: 'none',
                        textAlign: 'center',
                    }}
                >
                    <Box sx={{display:'flex', alignItems:'center',}}>
                        <Logo sx={{width:25,height:25}} />
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column'}}>
                        <Typography sx={{
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                        }}>小撮美食地圖</Typography>
                        <Typography sx={{
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            fontSize: '0.5rem',
                        }}>sotshau.map</Typography>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                      size="large"
                      aria-label="appbar menu icon"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                      {
                        pages.map((page) => {
                          return(
                            <MenuItem onClick={e => handleAction(e, page.path)} key={page.name}>
                              <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                            </MenuItem>    
                          )
                        })
                      }
                    </Menu>
                </Box>
                <Box
                    component="a"
                    href="#app-bar-with-responsive-menu"
                    sx={{
                        mx:0,
                        display: { xs: 'flex', md: 'none' },
                        flexDirection:'row',
                        flexGrow: 1,
                        color: 'inherit',
                        textDecoration: 'none',
                        textAlign: 'center',
                    }}
                >
                    <Box sx={{display:'flex', alignItems:'center',}}>
                      <Logo sx={{width:25,height:25}} />
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column'}}>
                      <Typography sx={{
                          fontWeight: 700,
                          letterSpacing: '.3rem',
                      }}>小撮美食地圖</Typography>
                      <Typography sx={{
                          fontFamily: 'monospace',
                          fontWeight: 700,
                          letterSpacing: '.3rem',
                          fontSize: '0.5rem',
                      }}>sotshau.map</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    onClick={e => handleAction(e, page.path)}
                    sx={{ my: 2, color: 'inherit', display: 'block' }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
            </Toolbar>
            <NaviBlur/>
          </Container>
        </AppBar>
      </header>
    );
  }

