import React from "react";
import {Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";

export default function YoutuberPicker({channels, handleChannelClick}) {
    return(
        <List>
            {channels && channels.map(c => {
                return (
                    <ListItem key={c.customUrl}>
                        <ListItemButton component="a" href={`#${c.channelId}`} onClick={handleChannelClick}>
                            <ListItemAvatar>
                                <Avatar alt={c.name} src={c.logo}></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={c.name} secondary={c.customUrl}/>
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    )
}