import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Avatar, Box, Card, CardContent, CardMedia, Container, Divider, Link, Stack, Typography} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Grid2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import {Helmet} from "react-helmet";

export default function Channel() {
    const initialChannelState = {
        "id": "",
        "channelId": "",
        "name": "",
        "customUrl": "",
        "thumbnailDefault": "",
        "thumbnailMedium": "",
        "thumbnailHigh": "",
        "description": "",
        "viewCount": 0,
        "subscriberCount": 0,
        "videoCount": 0,
        videos:[],
    }
    const { id } = useParams();
    const [channelInfo, setChannelInfo] = useState(initialChannelState);
    const navigate = useNavigate();
    const [videoTitles, setVideoTitles] = useState('');
    
    const sliderSettings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        duration: 5000,
        autoplaySpeed: 5000,
        arrows: false,
        easing: "linear",
    };

    const  handleVideoClick = (e, video) => {
        navigate(`/video?id=${video.id}&source=channel`);
    }
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`channel/id/${id}`);
            const data = await response.json();
            setChannelInfo(data);
            setVideoTitles(data.videos.map(v => v.name));
        }

        fetchData()
            .catch(console.error);

    }, [id]);
    
    return(
        <Box sx={{width:"100vw", mt:10, px:0, mx:0}}>
            <Helmet>
                <title>{`${channelInfo.name}帶你玩遍全台灣各地,品嚐各地美食 - 小撮美食地圖`}</title>
                <meta name="keywords"
                      content={`小撮美食地圖,${channelInfo.name}`}/>
                <meta name="description"
                      content={`來小撮美食地圖跟著知名的YouTube創作者【${channelInfo.name}】一起探索各地美食和令人驚嘆的景點。${videoTitles}`}/>
            </Helmet>
            <Box sx={{position: "relative", width: 'inherit', height: '25vh', backgroundColor: '#000'}}>
                <Box sx={{position: "absolute", right: 0, height: "100%", width: '85%'}}>
                    <Slider {...sliderSettings}>
                        {channelInfo.videos.map(v => {
                            return (
                                <Box key={v.id}
                                     sx={{width: '100%', height: '25vh', overflow: 'hidden', position: 'relative'}} >
                                    <Box component="img"
                                         src={v.thumbnail}
                                         alt={v.name}
                                         sx={{objectFit: 'cover', width: '100%', height: '100%' ,transform: 'transform 0.3s ease-in-out'}}
                                    ></Box>
                                </Box>
                            )
                        })}
                    </Slider>    
                </Box>
                <Box sx={{position: "absolute", height: "100%", width: '100%', backgroundColor:'rgb(0 0 0 / 20%)'}}>
                    <div style={{position: "relative", width: "100%", height: "100%", borderRadius: "0px"}}>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 1,
                                backdropFilter: "blur(0.15625px)",
                                WebkitBackdropFilter: "blur(0.15625px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0) 37.5%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 2,
                                backdropFilter: "blur(0.3125px)",
                                WebkitBackdropFilter: "blur(0.3125px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 12.5%, rgba(0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgba(0, 0, 0, 0) 50%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 3,
                                backdropFilter: "blur(0.625px)",
                                WebkitBackdropFilter: "blur(0.625px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 62.5%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 4,
                                backdropFilter: "blur(1.25px)",
                                WebkitBackdropFilter: "blur(1.25px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgba(0, 0, 0, 0) 75%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 5,
                                backdropFilter: "blur(2.5px)",
                                WebkitBackdropFilter: "blur(2.5px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 87.5%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 6,
                                backdropFilter: "blur(5px)",
                                WebkitBackdropFilter: "blur(5px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 7,
                                backdropFilter: "blur(10px)",
                                WebkitBackdropFilter: "blur(10px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                        <div
                            style={{
                                position: "absolute",
                                inset: "0px",
                                zIndex: 8,
                                backdropFilter: "blur(20px)",
                                WebkitBackdropFilter: "blur(20px)",
                                maskImage: "linear-gradient(to left, rgba(0, 0, 0, 0) 87.5%, rgb(0, 0, 0) 100%)",
                                borderRadius: "0px",
                                pointerEvents: "none"
                            }}></div>
                    </div>
                </Box>
                <Box sx={{position: "relative", height: "100%", width: '100%', zIndex: 10}}>
                    <Box sx={{
                        position: "relative",
                        height: '100%',
                        mx: 4,
                        display: "flex",
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                        <Stack spacing={1} sx={{width: '60%'}}>
                            <Avatar alt={channelInfo.name} src={channelInfo.thumbnailHigh}
                                    sx={{width: 65, height: 65}}/>
                            <Typography sx={{
                                fontSize: '1.25rem',
                                fontWeight: "bold",
                                color: "white"
                            }}>{channelInfo.name}</Typography>
                            <Typography
                                sx={{fontSize: '0.75rem', color: "grey.500"}}>{channelInfo.customUrl}</Typography>
                        </Stack>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                justifyContent:'center'}}>
                {channelInfo.videos.map(video => {
                    return (
                        <Box key={video.id} sx={{ mx:1, p:1, maxWidth:{md:300}, width:'100%'}}>
                            <Link
                                color="text.secondary"
                                underline='none'
                                onClick={(e) => handleVideoClick(e, video)}
                                sx={{
                                    "& :hover": {
                                        color: "text.primary",
                                        cursor: "pointer",
                                    }
                                }}
                            >
                                <Grid container spacing={1} sx={{pb:1}}>
                                    <Grid size={{ xs: 4, md: 12 }} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', }}>
                                        <Box
                                            component="img"
                                            sx={{
                                                width: "-webkit-fill-available",
                                                borderRadius: 1,
                                                my:'auto',
                                                maxWidth: {xs: 110, sm: 150, md: 300}
                                            }}
                                            alt="The house from the offer."
                                            src={video.thumbnail}
                                            loading="lazy"
                                        />
                                    </Grid>
                                    <Grid size={{ xs: 8, md: 12 }}>
                                        <Box>
                                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                                <CalendarMonthIcon size="small" />
                                                <Typography variant={"overline"}>{dayjs(video.releaseDate).format('YYYY-MM-DD')}</Typography>
                                            </Stack>
                                            <Typography variant="body1" gutterBottom>{video.name}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Link>  
                            <Divider />
                        </Box>
                        
                    )
                })}
            </Box>
        </Box>
    )
}