import React from "react";
import Grid from '@mui/material/Grid2';
import {Box, Chip, Link, Stack, Typography} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

export default function ChannelListVideoItem({video}){
    const navigate = useNavigate();
    const  handleVideoClick = (e, video) => {
        navigate(`/video?id=${video.id}&source=home`);
    }
    
    return(
        <Link
            color="text.secondary" 
            underline='none'
            onClick={(e) => handleVideoClick(e, video)}
            sx={{
                "& :hover": {
                    color: "text.primary",
                    cursor: "pointer",
                }
            }}
        >
            <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Box
                        component="img"
                        sx={{
                            width: "-webkit-fill-available",
                            borderRadius: 3,
                        }}
                        alt="The house from the offer."
                        src={video.thumbnail}
                        loading="lazy"
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                    <Box>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <CalendarMonthIcon size="small" />
                            <Typography variant={"overline"}>{dayjs(video.releaseDate).format('YYYY-MM-DD')}</Typography>
                        </Stack>
                        <Typography variant="body1" gutterBottom>{video.name}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Link>
        
    )
}