import React, {useRef} from "react";
import Grid from '@mui/material/Grid2';
import {Avatar, Box, Button, Divider, Link} from "@mui/material";
import ChannelListVideoItem from "./ChannelListVideoItem";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {useNavigate} from "react-router-dom";

export default function ChannelListItem({channel}){
    const channelRef = useRef(channel);
    const navigate = useNavigate();
    
    const handleAllVideoClick = () => {
        navigate(`/youtuber/${channel.channelId}`);
    }
        
    return(
        <Grid id={channel.channelId} container spacing={1}>
            <Grid
                size={{ xs: 12, md: 3 }}
            >
                <Link
                    color="text.secondary" 
                    underline='none' 
                    target="_blank" 
                    rel="noopener" 
                    href={`https://www.youtube.com/${channel.customUrl}`}
                    sx={{
                        "& :hover": {
                            color: "primary.main"
                        }
                    }}
                >
                    <Grid 
                        container 
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'column' }}
                    >
                        <Grid><Avatar alt={channel.name} src={channel.logo} sx={{ width: 56, height: 56 }} /> </Grid>
                        <Grid>{channel.customUrl}</Grid>
                        <Grid>{channel.name}</Grid>
                    </Grid>
                </Link>
            </Grid>
            <Grid size={{ xs: 12, md: 9 }}>
                {channel.videos && channel.videos.map(v => {
                    return (
                        <Box key={v.id} sx={{py:1}}>
                            <ChannelListVideoItem video={v} />
                            <Divider/>    
                        </Box>
                    )
                })}

                {channel.videos.length > 10 && 
                <Box sx={{py:2, width:'100%', display:'flex', justifyContent:'center'}}>
                    <Button startIcon={<ExpandMoreOutlinedIcon/>} onClick={handleAllVideoClick}>
                        所有影片
                    </Button>
                </Box>}
            </Grid>
        </Grid>
    )
}