import React, {useEffect} from "react";
import {Box} from "@mui/material";

export default function SiteMap() {
    useEffect(() => {
        const fetchSitemap = async () => {
            const response = await fetch('seo/sitemap');
            
        };

        fetchSitemap().then(r => {});
    }, []);
    return (
        <Box>
        
        </Box>
    )
}