import {
    Box,
    IconButton,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MyPositionIcon from "../svg-icons/MyPositionIcon";
import "./VideoMap.css";

export default function VideoMap({places}) {
    
    const [myLocationMarker, setMyLocationMarker] = useState(null);
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current === null || mapRef.current === undefined) {
            const m = L.map('map', {
                zoomControl: false
            }).setView([25.033641508276443, 121.5646626382956], 13);

            // 移動zoom control位置
            L.control.zoom({
                position: 'topright'
            }).addTo(m);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                minZoom: 3,
                maxZoom: 20,
                maxNativeZoom: 19,
            }).addTo(m);

            mapRef.current = m;
        }

    }, []);
    
    useEffect(() => {
        // 景點打點，並顯示景點名稱
        const spots = GetSpots(places);
        if (spots.length === 0) {return;}
        const bounds = L.latLngBounds();
        const tooltipOption =
            {
                direction: 'right',
                permanent: true,
                sticky: false,
                offset: [14, -16],
                opacity: 0.75,
                className: 'leaflet-tooltip-own'
            };

        const svgIcon = L.divIcon({
            html: `
                <svg height="32px" width="32px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504.123 504.123" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path style="fill:#0CC18E;" d="M429.064,177.018C429.064,79.258,349.822,0,252.054,0C154.301,0,75.059,79.258,75.059,177.018 c0,49.451,21.638,105.614,53.035,149.323l123.975,177.782l124.006-177.814C413.452,272.864,429.064,226.438,429.064,177.018z"></path> <path style="fill:#17DD9F;" d="M252.054,0C154.301,0,75.059,79.258,75.059,177.018"></path> <path style="fill:#039176;" d="M252.069,504.123l124.006-177.814c37.368-53.445,52.988-99.872,52.988-149.291 C429.064,79.258,349.822,0,252.054,0"></path> <path style="fill:#047F73;" d="M429.064,177.018C429.064,79.258,349.822,0,252.054,0"></path> <circle style="fill:#F44D71;" cx="252.062" cy="166.936" r="79.557"></circle> <path style="fill:#FF6679;" d="M172.528,166.936c0-43.93,35.604-79.557,79.525-79.557"></path> <path style="fill:#D60949;" d="M252.054,87.387c43.922,0,79.557,35.619,79.557,79.557c0,43.922-35.627,79.525-79.557,79.525"></path> <path style="fill:#B50444;" d="M331.603,166.936c0,43.922-35.627,79.525-79.557,79.525"></path> </g></svg>`,
            className: "",
            iconSize: [32, 32],
            iconAnchor: [16, 32],
        });

        for (let i = 0; i < spots.length; i++) {
            const latLng = [spots[i].lat, spots[i].lng];
            L.marker(latLng, { icon: svgIcon })
                .addTo(mapRef.current)
                .bindTooltip(spots[i].name, tooltipOption);
            bounds.extend(latLng);
        }
        mapRef.current.fitBounds(bounds);

        // 在小於指定zoom level時，關閉tooltip，避免tooltip交疊在一起
        let mapBounds;
        let markersDisplayed = false;

        mapRef.current.on('moveend zoomend', function (e) {
            mapBounds = mapRef.current.getBounds();
            const zoom = mapRef.current.getZoom();
            if (zoom > 10) {
                mapRef.current.eachLayer(function (layer) {
                    if (layer instanceof L.Marker && mapBounds.contains(layer.getLatLng())) {
                        markersDisplayed = true;
                        layer.openTooltip();
                    }
                });
            }
            else if (markersDisplayed) {
                markersDisplayed = false;
                mapRef.current.eachLayer(function (layer) {
                    if (layer instanceof L.Marker && mapBounds.contains(layer.getLatLng())) {
                        layer.closeTooltip();
                    }
                });
            }
        });

        mapRef.current.on('locationfound', onLocationFound);




    }, [places]);


    function GetSpots(values) {
        let spots = [];
        for (let i = 0; i < places.length; i++) {
            let spotObj = {
                "name": values[i].placeDetail.name,
                "address": values[i].placeDetail.address,
                "lat": values[i].placeDetail.latitude,
                "lng": values[i].placeDetail.longitude,
            };
            spots.push(spotObj);
        }
        return spots;
    }

    function onLocationFound(e) {
        //var radius = e.accuracy;
        if (myLocationMarker === null) {
            const svgIcon = L.divIcon({
                html: `
                <svg height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <circle style="fill:#00b4e0;" cx="255.996" cy="193.345" r="96.759"></circle>
                  <g>
                    <path style="fill:#006e8a;" d="M256,304.456c-61.264,0-111.107-49.842-111.107-111.105S194.735,82.244,256,82.244 s111.107,49.842,111.107,111.107C367.107,254.615,317.265,304.456,256,304.456z M256,110.943 c-45.439,0-82.408,36.967-82.408,82.408c0,45.439,36.969,82.406,82.408,82.406s82.408-36.967,82.408-82.406 C338.408,147.91,301.439,110.943,256,110.943z"></path>
                    <path style="fill:#006e8a;" d="M256,0C149.386,0,62.649,86.737,62.649,193.351c0,72.07,39.642,135.049,98.262,168.299 l82.662,143.175c2.563,4.44,7.301,7.175,12.427,7.175c5.126,0,9.864-2.735,12.427-7.175l84.026-145.537 c3.963-6.863,1.611-15.639-5.252-19.601c-6.866-3.963-15.639-1.611-19.601,5.252l-71.598,124.013l-51.518-89.231 c16.409,4.542,33.683,6.98,51.518,6.98c7.924,0,14.349-6.424,14.349-14.349c0-7.925-6.426-14.35-14.349-14.35 c-90.791,0-164.652-73.863-164.652-164.652S165.211,28.699,256,28.699s164.652,73.863,164.652,164.652 c0,40.902-15.099,80.118-42.515,110.421c-5.316,5.878-4.863,14.951,1.014,20.269c5.876,5.316,14.951,4.862,20.269-1.014 c32.197-35.591,49.93-81.644,49.93-129.675C449.351,86.737,362.614,0,256,0z"></path>
                  </g>
                 </g></svg>`,
                className: "",
                iconSize: [32, 32],
                iconAnchor: [0, 0],
            });
            const marker = L.marker(e.latlng, { icon: svgIcon }).addTo(mapRef.current);
            setMyLocationMarker(marker);
        } else {
            myLocationMarker.setLatLng(e.latlng);
        }

        //L.circle(e.latlng, radius).addTo(map);
    }
    const myPosition = function (elem, evt) {
        mapRef.current.locate({ setView: true, maxZoom: 16 });
    }

    // 點擊POI卡片，將地圖移動到該位置，因資訊欄的關係會向南修正500公尺
    const flyTo = function (elem, evt) {
        const radiusInKm = 0.1;
        const angleInDegrees = 180;
        const latLng = L.latLng([elem.querySelector('.poi-lat').innerHTML, elem.querySelector('.poi-lng').innerHTML]);

        const adjustLatLng = L.GeometryUtil.destination(latLng, angleInDegrees, radiusInKm * 1000);

        mapRef.current.setView(adjustLatLng, 17);
    }

    const actions = {
        click: {
            myPosition,
            flyTo,
        },
        touchstart: {
        }
    };

    Object.keys(actions).forEach(key => document.addEventListener(key, handle));

    function handle(evt) {
        const origin = evt.target.closest("[data-action]");
        return origin &&
            actions[evt.type] &&
            actions[evt.type][origin.dataset.action] &&
            actions[evt.type][origin.dataset.action](origin, evt) ||
            true;
    }
    
    return(
        <Box sx={{position:'relative', width:'100%', height:'100%'}}>
            <Box id={'map'} width={'100%'} height={'100%'}>
            </Box>
            <IconButton sx={{position:"absolute", width:50, height:50, bottom:'25px', right:"10px", zIndex:9999, bgcolor:'rgba(255, 255, 255, 0.75)'}}>
                <MyPositionIcon sx={{width:"2rem", height:"2rem", p:"5px", bgcolor: "white",  borderRadius:'50%', borderColor:'#006e8a', fill:'none'}} />
            </IconButton>
        </Box>
    )
}